import { Entry_Places_Place } from '@core/gql/graphql';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface PlacesStore {
	places: Entry_Places_Place[];
	setPlaces: (places: Entry_Places_Place[]) => void;
}

export const usePlacesStore = create<PlacesStore>((set) => ({
	places: [],
	setPlaces: (places) => set({ places }),
}));

interface SelectedPlaceStore {
	place: Entry_Places_Place | null;
	setPlace: (place: Entry_Places_Place | null) => void;
}

export const useSelectedPlaceStore = create<SelectedPlaceStore>()(
	persist(
		(set) => ({
			place: null,
			setPlace: (place) => set({ place }),
		}),
		{ name: 'place' },
	),
);

interface PlaceModalStore {
	isOpen: boolean;
	toggle: (value: boolean) => void;
}

export const usePlaceModalStore = create<PlaceModalStore>((set) => ({
	isOpen: false,
	toggle: (value) => set({ isOpen: value }),
}));
