import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from 'react-router-dom';
import React, { useCallback, useEffect } from 'react';
import RouteLoadingFallback from '@atoms/RouteLoadingFallback/RouteLoadingFallback';
import { RoutesType } from '@core/enums/routes-type.enum';
import ProtectedRoute from '@modules/ProtectedRoute/ProtectedRoute';
import DashboardLayout from '@modules/DashboardLayout/DashboardLayout';
import { useIsMobileStore } from '@state/common.state';
import { useAuth } from '@hooks/useAuth';

const Home = React.lazy(() => import('@routes/Home/HomePage'));
const ErrorPage = React.lazy(() => import('@routes/ErrorPage/ErrorPage'));
const NotFoundPage = React.lazy(() => import('@routes/NotFoundPage/NotFoundPage'));
const Dashboard = React.lazy(() => import('@routes/DashboardPage/DashboardPage'));
const ReportCounterStatus = React.lazy(() => import('@routes/ReportCounterStatusPage/ReportCounterStatusPage'));
const InvoicesPage = React.lazy(() => import('@routes/InvoicesPage/InvoicesPage'));
const OrdersPage = React.lazy(() => import('@routes/OrdersPage/OrdersPage'));
const StatisticsPage = React.lazy(() => import('@routes/StatisticsPage/StatisticsPage'));
const FormsPage = React.lazy(() => import('@routes/FormsPage/FormsPage'));
const ContractsPage = React.lazy(() => import('@routes/ContractsPage/ContractsPage'));
const SettingsPage = React.lazy(() => import('@routes/SettingsPage/SettingsPage'));
const EditPlacesPage = React.lazy(() => import('@routes/EditPlacesPage/EditPlacesPage'));
const FaqPage = React.lazy(() => import('@routes/FaqPage/FaqPage'));
const MessagesPage = React.lazy(() => import('@routes/MessagesPage/MessagesPage'));
const MessageDetails = React.lazy(() => import('@routes/MessageDetails/MessageDetails'));

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route errorElement={<ErrorPage />}>
			<Route
				path="*"
				element={
					<React.Suspense fallback={<RouteLoadingFallback />}>
						<NotFoundPage />
					</React.Suspense>
				}
			/>

			{/* <Route */}
			{/*	path={RoutesType.COOKIE_NOTICE} */}
			{/*	element={ */}
			{/*		<React.Suspense fallback={<RouteLoadingFallback />}> */}
			{/*			<InfoPage type={RoutesType.COOKIE_NOTICE} /> */}
			{/*		</React.Suspense> */}
			{/*	} */}
			{/* /> */}

			{/* <Route */}
			{/*	path={RoutesType.PRIVACY_POLICY} */}
			{/*	element={ */}
			{/*		<React.Suspense fallback={<RouteLoadingFallback />}> */}
			{/*			<InfoPage type={RoutesType.PRIVACY_POLICY} /> */}
			{/*		</React.Suspense> */}
			{/*	} */}
			{/* /> */}

			{/* <Route */}
			{/*	path={RoutesType.TERMS_AND_CONDITIONS} */}
			{/*	element={ */}
			{/*		<React.Suspense fallback={<RouteLoadingFallback />}> */}
			{/*			<InfoPage type={RoutesType.TERMS_AND_CONDITIONS} /> */}
			{/*		</React.Suspense> */}
			{/*	} */}
			{/* /> */}

			{/* <Route */}
			{/*	path={RoutesType.CONTACTS} */}
			{/*	element={ */}
			{/*		<React.Suspense fallback={<RouteLoadingFallback />}> */}
			{/*			<InfoPage type={RoutesType.CONTACTS} /> */}
			{/*		</React.Suspense> */}
			{/*	} */}
			{/* /> */}

			<Route element={<ProtectedRoute shouldBeUser={false} />}>
				<Route
					path={RoutesType.LOGIN}
					element={
						<React.Suspense fallback={<RouteLoadingFallback />}>
							<Home type={RoutesType.LOGIN} />
						</React.Suspense>
					}
				/>
				<Route
					path={RoutesType.LOGIN_CONFIRM}
					element={
						<React.Suspense fallback={<RouteLoadingFallback />}>
							<Home type={RoutesType.LOGIN_CONFIRM} />
						</React.Suspense>
					}
				/>
				<Route
					path={RoutesType.REGISTER}
					element={
						<React.Suspense fallback={<RouteLoadingFallback />}>
							<Home type={RoutesType.REGISTER} />
						</React.Suspense>
					}
				/>
				<Route
					path={RoutesType.CONFIRM_EMAIL}
					element={
						<React.Suspense fallback={<RouteLoadingFallback />}>
							<Home type={RoutesType.CONFIRM_EMAIL} />
						</React.Suspense>
					}
				/>
				<Route
					path={RoutesType.FORGOT_PASSWORD}
					element={
						<React.Suspense fallback={<RouteLoadingFallback />}>
							<Home type={RoutesType.FORGOT_PASSWORD} />
						</React.Suspense>
					}
				/>
			</Route>
			<Route
				path={RoutesType.SET_PASSWORD}
				element={
					<React.Suspense fallback={<RouteLoadingFallback />}>
						<Home type={RoutesType.SET_PASSWORD} />
					</React.Suspense>
				}
			/>

			<Route
				path={RoutesType.ERROR_PAGE}
				element={
					<React.Suspense fallback={<RouteLoadingFallback />}>
						<ErrorPage />
					</React.Suspense>
				}
			/>

			<Route element={<ProtectedRoute />}>
				<Route element={<DashboardLayout showGreeting />}>
					<Route
						path={RoutesType.ROOT}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<Dashboard />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.INVOICES}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<InvoicesPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.ORDERS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<OrdersPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.REPORT_COUNTER_STATUS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<ReportCounterStatus />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout containerClass="" />}>
					<Route
						path={RoutesType.STATISTICS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<StatisticsPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.FORMS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<FormsPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.CONTRACTS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<ContractsPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.SETTINGS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<SettingsPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.MESSAGES}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<MessagesPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.MESSAGE_DETAILS}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<MessageDetails />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.EDIT_PLACES}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<EditPlacesPage />
							</React.Suspense>
						}
					/>
				</Route>

				<Route element={<DashboardLayout />}>
					<Route
						path={RoutesType.FAQ}
						element={
							<React.Suspense fallback={<RouteLoadingFallback />}>
								<FaqPage />
							</React.Suspense>
						}
					/>
				</Route>
			</Route>
		</Route>,
	),
);

export function Navigation() {
	const { setProfile } = useAuth();
	const setIsMobile = useIsMobileStore((state) => state.setIsMobile);

	const calcViewport = useCallback(() => {
		const vh = window.innerHeight * 0.01;
		const vw = window.innerWidth * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		document.documentElement.style.setProperty('--vw', `${vw}px`);
		setIsMobile(window.innerWidth < 992);
	}, [setIsMobile]);

	const handleScroll = useCallback(() => {
		document.body.classList.toggle('is-scrolled', window.pageYOffset > 80);
	}, []);

	useEffect(() => {
		calcViewport();
		window.addEventListener('resize', calcViewport);
		window.addEventListener('orientationchange', calcViewport);
		window.addEventListener('scroll', handleScroll);

		return () => {
			window.removeEventListener('resize', calcViewport);
			window.removeEventListener('orientationchange', calcViewport);
			window.removeEventListener('scroll', handleScroll);
		};
	}, [calcViewport, handleScroll]);

	useEffect(() => {
		setProfile();
	}, [setProfile]);

	return <RouterProvider router={router} />;
}
