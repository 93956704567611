/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n\tquery CreatePlace($external_document_number: String, $recipient_number: String, $tax_number: String) {\n\t\tcreate_place(external_document_number: $external_document_number, recipient_number: $recipient_number, tax_number: $tax_number) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n": types.CreatePlaceDocument,
    "\n\tquery CreateTask($type: String, $body: String, $id: String) {\n\t\tcreate_task(type: $type, body: $body, id: $id) {\n\t\t\ttype\n\t\t}\n\t}\n": types.CreateTaskDocument,
    "\n\tquery EditPLace($title: String, $id: String, $invoiceNotifications: Boolean) {\n\t\tedit_place(title: $title, id: $id, invoiceNotifications: $invoiceNotifications) {\n\t\t\tid\n\t\t}\n\t}\n": types.EditPLaceDocument,
    "\n\tquery FaqQuery {\n\t\tglobal_admin(type: \"faq\") {\n\t\t\t... on GlobalSet_Faq {\n\t\t\t\tquestions {\n\t\t\t\t\t... on Set_Questions_NovKomplet {\n\t\t\t\t\t\tquestion\n\t\t\t\t\t\tanswer\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.FaqQueryDocument,
    "\n\tquery FormsQuery {\n\t\tglobal_admin(type: \"forms\") {\n\t\t\t... on GlobalSet_Forms {\n\t\t\t\tforms_type {\n\t\t\t\t\t... on Set_FormsType_NovKomplet {\n\t\t\t\t\t\tform_name\n\t\t\t\t\t\tlink\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.FormsQueryDocument,
    "\n\tquery ContractsQuery($id: String!) {\n\t\tentry(type: \"places\", filter: { id: $id }) {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tAgreementNo\n\t\t\t\t\t\tStartDate\n\t\t\t\t\t\tEndDate\n\t\t\t\t\t\tStatus\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.ContractsQueryDocument,
    "\n\tquery CounterEnteredQuery($id: String!) {\n\t\tcounter_entered(place_id: $id) {\n\t\t\talready_entered\n\t\t\tPosting_Date\n\t\t\tEnding_Counter_Quantity\n\t\t}\n\t}\n": types.CounterEnteredQueryDocument,
    "\n\tquery CounterNumber($place_id: String) {\n\t\tcounter_number(place_id: $place_id) {\n\t\t\tcounter_number\n\t\t}\n\t}\n": types.CounterNumberDocument,
    "\n\tquery CountersQuery($id: String!) {\n\t\tcounter_query(place_id: $id) {\n\t\t\titems {\n\t\t\t\tEntry_No\n\t\t\t\tPosting_Date\n\t\t\t\tEnding_Counter_Quantity\n\t\t\t\tReported_State\n\t\t\t\tPavsal\n\t\t\t}\n\t\t}\n\t}\n": types.CountersQueryDocument,
    "\n\tquery GetGlobals {\n\t\tglobal_admin(type: \"admin\") {\n\t\t\t... on GlobalSet_Admin {\n\t\t\t\tend_date_counter\n\t\t\t\tstart_date_counter\n\t\t\t}\n\t\t}\n\t}\n": types.GetGlobalsDocument,
    "\n\tquery GetInvoicePdf($cust_ledger_entry: String, $place_id: String) {\n\t\treceipt_pdf(cust_ledger_entry: $cust_ledger_entry, place_id: $place_id) {\n\t\t\tPDF\n\t\t}\n\t}\n": types.GetInvoicePdfDocument,
    "\n\tquery ReceiptAmount($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\ttotal_remaining_amount\n\t\t}\n\t}\n": types.ReceiptAmountDocument,
    "\n\tquery Receipt($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\titems {\n\t\t\t\tKey\n\t\t\t\tEntry_No\n\t\t\t\tCustomer_No\n\t\t\t\tPosting_Date\n\t\t\t\tDocument_Type\n\t\t\t\tDocument_No\n\t\t\t\tDescription\n\t\t\t\tAmount\n\t\t\t\tRemaining_Amount\n\t\t\t\tOpen\n\t\t\t\tApplies_to_Doc_Type\n\t\t\t\tFileName\n\t\t\t}\n\t\t}\n\t}\n": types.ReceiptDocument,
    "\n\tquery LastMonthStatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n": types.LastMonthStatisticsQueryDocument,
    "\n\tquery GetPlaces {\n\t\tentry(type: \"places\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tid\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tPayer_name\n\t\t\t\t\t\tStreetPayer\n\t\t\t\t\t\tCityPayer\n\t\t\t\t\t\tPostalCodePayer\n\t\t\t\t\t\tSellTo_name\n\t\t\t\t\t}\n\t\t\t\t\tFunctionaLoc_code\n\t\t\t\t\texternal_document_number\n\t\t\t\t\trecipient_number\n\t\t\t\t\ttax_number\n\t\t\t\t\ttitle\n\t\t\t\t\tNAVid\n\t\t\t\t\tStreet1\n\t\t\t\t\tCity\n\t\t\t\t\tPostalCode\n\t\t\t\t\tCRMid\n\t\t\t\t\tinvoiceNotifications\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.GetPlacesDocument,
    "\n\tquery StatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n": types.StatisticsQueryDocument,
    "\n\tquery GetTranslations {\n\t\tglobal_admin(type: \"translations\") {\n\t\t\t... on GlobalSet_Translations {\n\t\t\t\tprivacy_policy\n\t\t\t\tcookies\n\t\t\t\tterms_and_conditions\n\t\t\t}\n\t\t}\n\t}\n": types.GetTranslationsDocument,
    "\n\tquery NewsQuery {\n\t\tentry(type: \"news\", sort: \"date desc\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_News_News {\n\t\t\t\t\tid\n\t\t\t\t\tdetail_image\n\t\t\t\t\tnews_url\n\t\t\t\t\ttitle\n\t\t\t\t\tread_more_text\n\t\t\t\t\tdate(format: \"d. m. Y\")\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n": types.NewsQueryDocument,
    "\n\tquery RemovePlace($id: String) {\n\t\tremove_place(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n": types.RemovePlaceDocument,
    "\n\tquery ReportCounter($counter_number: String, $place_id: String, $value: String, $counter_type: String) {\n\t\tcreate_counter(counter_number: $counter_number, place_id: $place_id, value: $value, counter_type: $counter_type) {\n\t\t\tplace_id\n\t\t\tfull_date\n\t\t\tvalue\n\t\t}\n\t}\n": types.ReportCounterDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery CreatePlace($external_document_number: String, $recipient_number: String, $tax_number: String) {\n\t\tcreate_place(external_document_number: $external_document_number, recipient_number: $recipient_number, tax_number: $tax_number) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery CreatePlace($external_document_number: String, $recipient_number: String, $tax_number: String) {\n\t\tcreate_place(external_document_number: $external_document_number, recipient_number: $recipient_number, tax_number: $tax_number) {\n\t\t\titems {\n\t\t\t\tid\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery CreateTask($type: String, $body: String, $id: String) {\n\t\tcreate_task(type: $type, body: $body, id: $id) {\n\t\t\ttype\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery CreateTask($type: String, $body: String, $id: String) {\n\t\tcreate_task(type: $type, body: $body, id: $id) {\n\t\t\ttype\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery EditPLace($title: String, $id: String, $invoiceNotifications: Boolean) {\n\t\tedit_place(title: $title, id: $id, invoiceNotifications: $invoiceNotifications) {\n\t\t\tid\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery EditPLace($title: String, $id: String, $invoiceNotifications: Boolean) {\n\t\tedit_place(title: $title, id: $id, invoiceNotifications: $invoiceNotifications) {\n\t\t\tid\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery FaqQuery {\n\t\tglobal_admin(type: \"faq\") {\n\t\t\t... on GlobalSet_Faq {\n\t\t\t\tquestions {\n\t\t\t\t\t... on Set_Questions_NovKomplet {\n\t\t\t\t\t\tquestion\n\t\t\t\t\t\tanswer\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery FaqQuery {\n\t\tglobal_admin(type: \"faq\") {\n\t\t\t... on GlobalSet_Faq {\n\t\t\t\tquestions {\n\t\t\t\t\t... on Set_Questions_NovKomplet {\n\t\t\t\t\t\tquestion\n\t\t\t\t\t\tanswer\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery FormsQuery {\n\t\tglobal_admin(type: \"forms\") {\n\t\t\t... on GlobalSet_Forms {\n\t\t\t\tforms_type {\n\t\t\t\t\t... on Set_FormsType_NovKomplet {\n\t\t\t\t\t\tform_name\n\t\t\t\t\t\tlink\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery FormsQuery {\n\t\tglobal_admin(type: \"forms\") {\n\t\t\t... on GlobalSet_Forms {\n\t\t\t\tforms_type {\n\t\t\t\t\t... on Set_FormsType_NovKomplet {\n\t\t\t\t\t\tform_name\n\t\t\t\t\t\tlink\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ContractsQuery($id: String!) {\n\t\tentry(type: \"places\", filter: { id: $id }) {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tAgreementNo\n\t\t\t\t\t\tStartDate\n\t\t\t\t\t\tEndDate\n\t\t\t\t\t\tStatus\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ContractsQuery($id: String!) {\n\t\tentry(type: \"places\", filter: { id: $id }) {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tAgreementNo\n\t\t\t\t\t\tStartDate\n\t\t\t\t\t\tEndDate\n\t\t\t\t\t\tStatus\n\t\t\t\t\t}\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery CounterEnteredQuery($id: String!) {\n\t\tcounter_entered(place_id: $id) {\n\t\t\talready_entered\n\t\t\tPosting_Date\n\t\t\tEnding_Counter_Quantity\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery CounterEnteredQuery($id: String!) {\n\t\tcounter_entered(place_id: $id) {\n\t\t\talready_entered\n\t\t\tPosting_Date\n\t\t\tEnding_Counter_Quantity\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery CounterNumber($place_id: String) {\n\t\tcounter_number(place_id: $place_id) {\n\t\t\tcounter_number\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery CounterNumber($place_id: String) {\n\t\tcounter_number(place_id: $place_id) {\n\t\t\tcounter_number\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery CountersQuery($id: String!) {\n\t\tcounter_query(place_id: $id) {\n\t\t\titems {\n\t\t\t\tEntry_No\n\t\t\t\tPosting_Date\n\t\t\t\tEnding_Counter_Quantity\n\t\t\t\tReported_State\n\t\t\t\tPavsal\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery CountersQuery($id: String!) {\n\t\tcounter_query(place_id: $id) {\n\t\t\titems {\n\t\t\t\tEntry_No\n\t\t\t\tPosting_Date\n\t\t\t\tEnding_Counter_Quantity\n\t\t\t\tReported_State\n\t\t\t\tPavsal\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetGlobals {\n\t\tglobal_admin(type: \"admin\") {\n\t\t\t... on GlobalSet_Admin {\n\t\t\t\tend_date_counter\n\t\t\t\tstart_date_counter\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetGlobals {\n\t\tglobal_admin(type: \"admin\") {\n\t\t\t... on GlobalSet_Admin {\n\t\t\t\tend_date_counter\n\t\t\t\tstart_date_counter\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetInvoicePdf($cust_ledger_entry: String, $place_id: String) {\n\t\treceipt_pdf(cust_ledger_entry: $cust_ledger_entry, place_id: $place_id) {\n\t\t\tPDF\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetInvoicePdf($cust_ledger_entry: String, $place_id: String) {\n\t\treceipt_pdf(cust_ledger_entry: $cust_ledger_entry, place_id: $place_id) {\n\t\t\tPDF\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ReceiptAmount($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\ttotal_remaining_amount\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ReceiptAmount($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\ttotal_remaining_amount\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery Receipt($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\titems {\n\t\t\t\tKey\n\t\t\t\tEntry_No\n\t\t\t\tCustomer_No\n\t\t\t\tPosting_Date\n\t\t\t\tDocument_Type\n\t\t\t\tDocument_No\n\t\t\t\tDescription\n\t\t\t\tAmount\n\t\t\t\tRemaining_Amount\n\t\t\t\tOpen\n\t\t\t\tApplies_to_Doc_Type\n\t\t\t\tFileName\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery Receipt($id: String!, $customer_num: String!, $end_date: String!, $start_date: String!) {\n\t\treceipt(id: $id, customer_num: $customer_num, end_date: $end_date, start_date: $start_date) {\n\t\t\titems {\n\t\t\t\tKey\n\t\t\t\tEntry_No\n\t\t\t\tCustomer_No\n\t\t\t\tPosting_Date\n\t\t\t\tDocument_Type\n\t\t\t\tDocument_No\n\t\t\t\tDescription\n\t\t\t\tAmount\n\t\t\t\tRemaining_Amount\n\t\t\t\tOpen\n\t\t\t\tApplies_to_Doc_Type\n\t\t\t\tFileName\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery LastMonthStatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery LastMonthStatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetPlaces {\n\t\tentry(type: \"places\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tid\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tPayer_name\n\t\t\t\t\t\tStreetPayer\n\t\t\t\t\t\tCityPayer\n\t\t\t\t\t\tPostalCodePayer\n\t\t\t\t\t\tSellTo_name\n\t\t\t\t\t}\n\t\t\t\t\tFunctionaLoc_code\n\t\t\t\t\texternal_document_number\n\t\t\t\t\trecipient_number\n\t\t\t\t\ttax_number\n\t\t\t\t\ttitle\n\t\t\t\t\tNAVid\n\t\t\t\t\tStreet1\n\t\t\t\t\tCity\n\t\t\t\t\tPostalCode\n\t\t\t\t\tCRMid\n\t\t\t\t\tinvoiceNotifications\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetPlaces {\n\t\tentry(type: \"places\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_Places_Place {\n\t\t\t\t\tid\n\t\t\t\t\tcontracts {\n\t\t\t\t\t\tPayer_name\n\t\t\t\t\t\tStreetPayer\n\t\t\t\t\t\tCityPayer\n\t\t\t\t\t\tPostalCodePayer\n\t\t\t\t\t\tSellTo_name\n\t\t\t\t\t}\n\t\t\t\t\tFunctionaLoc_code\n\t\t\t\t\texternal_document_number\n\t\t\t\t\trecipient_number\n\t\t\t\t\ttax_number\n\t\t\t\t\ttitle\n\t\t\t\t\tNAVid\n\t\t\t\t\tStreet1\n\t\t\t\t\tCity\n\t\t\t\t\tPostalCode\n\t\t\t\t\tCRMid\n\t\t\t\t\tinvoiceNotifications\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery StatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery StatisticsQuery($id: String!, $customer_num: String!, $year: String!) {\n\t\tstatistics(id: $id, customer_num: $customer_num, year: $year) {\n\t\t\titems {\n\t\t\t\tPosting_Date\n\t\t\t\tAmount\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery GetTranslations {\n\t\tglobal_admin(type: \"translations\") {\n\t\t\t... on GlobalSet_Translations {\n\t\t\t\tprivacy_policy\n\t\t\t\tcookies\n\t\t\t\tterms_and_conditions\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery GetTranslations {\n\t\tglobal_admin(type: \"translations\") {\n\t\t\t... on GlobalSet_Translations {\n\t\t\t\tprivacy_policy\n\t\t\t\tcookies\n\t\t\t\tterms_and_conditions\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery NewsQuery {\n\t\tentry(type: \"news\", sort: \"date desc\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_News_News {\n\t\t\t\t\tid\n\t\t\t\t\tdetail_image\n\t\t\t\t\tnews_url\n\t\t\t\t\ttitle\n\t\t\t\t\tread_more_text\n\t\t\t\t\tdate(format: \"d. m. Y\")\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery NewsQuery {\n\t\tentry(type: \"news\", sort: \"date desc\") {\n\t\t\tdata {\n\t\t\t\t... on Entry_News_News {\n\t\t\t\t\tid\n\t\t\t\t\tdetail_image\n\t\t\t\t\tnews_url\n\t\t\t\t\ttitle\n\t\t\t\t\tread_more_text\n\t\t\t\t\tdate(format: \"d. m. Y\")\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery RemovePlace($id: String) {\n\t\tremove_place(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery RemovePlace($id: String) {\n\t\tremove_place(id: $id) {\n\t\t\tstatus\n\t\t}\n\t}\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n\tquery ReportCounter($counter_number: String, $place_id: String, $value: String, $counter_type: String) {\n\t\tcreate_counter(counter_number: $counter_number, place_id: $place_id, value: $value, counter_type: $counter_type) {\n\t\t\tplace_id\n\t\t\tfull_date\n\t\t\tvalue\n\t\t}\n\t}\n"): (typeof documents)["\n\tquery ReportCounter($counter_number: String, $place_id: String, $value: String, $counter_type: String) {\n\t\tcreate_counter(counter_number: $counter_number, place_id: $place_id, value: $value, counter_type: $counter_type) {\n\t\t\tplace_id\n\t\t\tfull_date\n\t\t\tvalue\n\t\t}\n\t}\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;