import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import siteLogo from '@images/logo.svg';
import siteLogoSecondary from '@images/logo-secondary.svg';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { useCurrentPath } from '@hooks/useCurrentPath';
import { usePlacesStore, useSelectedPlaceStore } from '@state/places.state';
import { Entry_Places_Place } from '@core/gql/graphql';
import { useGetUnread } from '@hooks/api/useGetUnread';
import { RoutesType } from '@/core/enums/routes-type.enum';
import './Sidebar.scss';
import Icons from '@/components/atoms/Icons/Icons';
import Dropdown from '@/components/atoms/Dropdown/Dropdown';

function Sidebar() {
	const { t } = useTranslation();
	const places = usePlacesStore((store) => store.places);
	const { unreadCount } = useGetUnread();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [selectedPlace, setSelectedPlace] = useSelectedPlaceStore((store) => [store.place, store.setPlace]);
	const currentPath = useCurrentPath();
	const location = useLocation();
	const navigation = useNavigate();

	const sideBarMenuItems = useMemo(
		() => [
			{
				text: t('sideBar.invoices'),
				linkTo: RoutesType.INVOICES,
				hide: !selectedPlace?.id,
			},
			{
				text: t('sideBar.orders'),
				linkTo: RoutesType.ORDERS,
			},
			{
				text: t('sideBar.reportCounterStatus'),
				linkTo: RoutesType.REPORT_COUNTER_STATUS,
				hide: !selectedPlace?.id,
			},
			{
				text: t('sideBar.statistics'),
				linkTo: RoutesType.STATISTICS,
				hide: !selectedPlace?.id,
			},
			{
				text: t('sideBar.forms'),
				linkTo: RoutesType.FORMS,
			},
			{
				text: t('sideBar.contracts'),
				linkTo: RoutesType.CONTRACTS,
				hide: !selectedPlace?.id,
			},
			{
				text: t('sideBar.messages'),
				linkTo: RoutesType.MESSAGES,
				hide: !selectedPlace?.id,
			},
		],
		[selectedPlace?.id, t],
	);

	const selectPlace = (selected: Entry_Places_Place) => {
		setSelectedPlace(selected);
		if (currentPath === RoutesType.MESSAGE_DETAILS) {
			navigation(RoutesType.MESSAGES);
		}
	};

	const toggleSidebar = () => {
		setIsOpen((open) => !open);
	};

	useEffect(() => {
		setIsOpen(false);
	}, [location, selectedPlace?.id, sideBarMenuItems, t]);

	return (
		<div className={classNames(['sidebar-wrapper', isOpen && 'is-sidebar-open'])}>
			<div className="sidebar">
				<motion.div
					animate={{ opacity: 1 }}
					className="sidebar__header : d-none : d-md-block"
					initial={{ opacity: 0 }}
					transition={{ duration: 0.3 }}
				>
					<Link className="sidebar__logo : d-flex-cc" to={RoutesType.ROOT}>
						<img alt={t('sideBar.logoImageAlt') || ''} height={38} src={siteLogo} width={216} />
					</Link>
				</motion.div>

				<div className="sidebar__inner">
					<div className="sidebar__menu">
						<motion.div
							animate={{ opacity: 1 }}
							className="sidebar__menu-dropdown"
							initial={{ opacity: 0 }}
							transition={{ delay: 0.3 }}
						>
							{places.length !== 0 && (
								<>
									{currentPath === RoutesType.ROOT ? <motion.div className="line" layoutId="line" /> : null}
									<Dropdown places={places} selectedPlace={selectedPlace} onSelect={selectPlace} />
								</>
							)}
						</motion.div>
						<ul>
							{sideBarMenuItems
								.filter(({ hide }) => !hide)
								.map(({ text, linkTo }, index) => (
									<motion.li
										key={`${text}-${index}`}
										animate={{ opacity: 1, translateY: 0 }}
										className="sidebar__menu-item"
										initial={{ opacity: 0, translateY: 15 }}
										transition={{ delay: index * 0.1 + 0.3, type: 'spring' }}
									>
										<>
											{currentPath.startsWith(linkTo) ? <motion.div className="line" layoutId="line" /> : null}
											<Link
												to={linkTo}
												className={classNames([
													'sidebar__link',
													'text--md',
													currentPath.startsWith(linkTo) && 'is-active',
												])}
											>
												{text}
												{linkTo === RoutesType.MESSAGES && unreadCount > 0 && (
													<div className="sidebar__link__unread">
														<span className="sidebar__link__unread__number">{unreadCount}</span>
													</div>
												)}
											</Link>
										</>
									</motion.li>
								))}
						</ul>
					</div>
				</div>

				<motion.div
					animate={{ opacity: 1 }}
					className="sidebar__settings : text--14"
					initial={{ opacity: 0 }}
					transition={{ delay: 0.9 }}
				>
					<Link
						className={classNames(['sidebar__setting-link', RoutesType.FAQ === currentPath && 'is-active'])}
						to={RoutesType.FAQ}
					>
						<Icons modifier="info" />
						<span className="sidebar__setting-link-text">{t('sideBar.helpAnswerLinkText')}</span>
					</Link>

					{!!places.length && (
						<Link
							className={classNames(['sidebar__setting-link', RoutesType.EDIT_PLACES === currentPath && 'is-active'])}
							to={RoutesType.EDIT_PLACES}
						>
							<Icons modifier="adjust" />
							<span className="sidebar__setting-link-text">{t('sideBar.editLinkText')}</span>
						</Link>
					)}
				</motion.div>
			</div>

			<div className="sidebar__footer : d-md-none">
				<Link className="sidebar__logo : d-flex-cc" to={RoutesType.ROOT}>
					<img alt={t('sideBar.logoImageAlt') || ''} height={38} src={siteLogoSecondary} width={216} />
				</Link>

				<button className="sidebar__hamburger" onClick={toggleSidebar}>
					<span aria-hidden="true" className="bars" />
					<span aria-hidden="true" className="bars" />
					<span aria-hidden="true" className="bars" />
				</button>
			</div>
		</div>
	);
}

export default React.memo(Sidebar);
