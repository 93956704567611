import { useLazyQuery } from '@apollo/client';
import { graphql } from '@core/gql/gql';
import { useGlobalsStore } from '@state/common.state';

const GET_GLOBALS_QUERY = graphql(/* GraphQL */ `
	query GetGlobals {
		global_admin(type: "admin") {
			... on GlobalSet_Admin {
				end_date_counter
				start_date_counter
			}
		}
	}
`);

export const useGetGlobals = () => {
	const setGlobals = useGlobalsStore((state) => state.setGlobals);
	const [getGlobals] = useLazyQuery(GET_GLOBALS_QUERY, {
		onCompleted: (response) => setGlobals(response.global_admin || {}),
	});

	return {
		getGlobals,
	};
};
