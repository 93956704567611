import React from 'react';
import { RoutesType } from '@core/enums/routes-type.enum';
import { Link } from 'react-router-dom';
import './Anchor.scss';
import classNames from 'classnames';
import Icons from '../Icons/Icons';

interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
	modifier?: 'primary' | 'large' | 'reverse' | 'without-arrow';
	href?: string;
	linkTo?: RoutesType;
	target?: string;
	children: React.ReactNode;
}

function Anchor({ modifier = 'primary', href, linkTo, children, ...props }: AnchorProps) {
	const className = classNames(['mod-link', modifier && `mod-link--${modifier}`]);
	const getChildren = () => (
		<>
			{children}
			{modifier !== 'without-arrow' && <Icons modifier="arrow-right" />}
		</>
	);

	if (href || props.onClick) {
		return (
			<a className={className} href={href || undefined} {...props}>
				{getChildren()}
			</a>
		);
	}

	if (linkTo) {
		return (
			<Link className={className} to={linkTo} {...props}>
				{getChildren()}
			</Link>
		);
	}

	return <div className={className}>{getChildren()}</div>;
}

export default React.memo(Anchor);
