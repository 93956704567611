import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import classNames from 'classnames';
import { Entry_Places_Place } from '@core/gql/graphql';
import { usePlaceModalStore } from '@state/places.state';
import Anchor from '../Anchor/Anchor';
import Icons from '../Icons/Icons';
import './Dropdown.scss';

interface DropdownProps {
	places: Entry_Places_Place[];
	selectedPlace: Entry_Places_Place | null;
	onSelect: (place: Entry_Places_Place) => void;
}

function Dropdown({ places, selectedPlace, onSelect }: DropdownProps) {
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const setPlacesModal = usePlaceModalStore((store) => store.toggle);
	const routeLocation = useLocation();

	const contentHandler = () => {
		setIsOpen((open) => !open);
	};

	const onValueSelect = (location: any) => {
		setIsOpen(false);
		onSelect(location);
	};

	useEffect(() => {
		setIsOpen(false);
	}, [routeLocation]);

	return (
		<div className={classNames(['dropdown', isOpen ? 'is-open' : ''])}>
			<button className="dropdown__header" onClick={contentHandler}>
				<span className="dropdown__label : text--14">{t('dropdown.label')}:</span>
				<span className="dropdown__title : text--18">{selectedPlace?.title}</span>
				<Icons modifier="chevron-down" />
			</button>

			<AnimatePresence>
				{isOpen && (
					<motion.div
						animate={{ height: 'auto', overflow: 'visible' }}
						className='className="dropdown__content"'
						exit={{ height: 0, overflow: 'hidden' }}
						initial={{ height: 0, overflow: 'hidden' }}
						transition={{ duration: 0.3 }}
					>
						<motion.ul animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ delay: 0.3 }}>
							{places
								.filter((place) => place.id !== selectedPlace?.id)
								.map((place, index) => (
									<motion.li
										key={`${place.id}-${index}`}
										animate="visible"
										className="dropdown__item"
										exit="hidden"
										initial="hidden"
										transition={{ duration: 0.8 }}
										onClick={() => onValueSelect(place)}
									>
										<div className="dropdown__item-name">{place.title}</div>
										<div className="dropdown__item-address : text--12">
											{place.Street1}
											<br />
											{place.PostalCode} {place.City}
										</div>
									</motion.li>
								))}
							<li className="dropdown__item : dropdown__item--has-link">
								<Anchor onClick={() => setPlacesModal(true)}>{t('dropdown.linkText')}</Anchor>
							</li>
						</motion.ul>
					</motion.div>
				)}
			</AnimatePresence>
		</div>
	);
}

export default React.memo(Dropdown);
