import React, { Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { useUserStore } from '@state/auth.state';
import classNames from 'classnames';

import Sidebar from '@/components/molecules/Sidebar/Sidebar';
import './DashboardLayout.scss';

const DashboardHeader = React.lazy(() => import('@molecules/DashboardHeader/DashboardHeader'));
const Footer = React.lazy(() => import('@modules/Footer/Footer'));
const PlaceModal = React.lazy(() => import('@molecules/PlaceModal/PlaceModal'));
const TermsModal = React.lazy(() => import('@molecules/TermsModal/TermsModal'));
const DrawerModal = React.lazy(() => import('@molecules/DrawerModal/DrawerModal'));

interface DashboardLayoutProps {
	children?: React.ReactNode;
	showGreeting?: boolean;
	containerClass?: string;
}

function DashboardLayout({ children, showGreeting, containerClass = 'container' }: DashboardLayoutProps) {
	const { user } = useUserStore();

	return (
		<>
			<section className="sc-general : sc-dashboard-layout">
				<div className="dashboard-layout">
					<Sidebar />

					<div className="dashboard-layout__main">
						<Suspense fallback={<div />}>
							<DashboardHeader showGreeting={showGreeting} />
						</Suspense>

						<div className="dashboard-layout__inner">
							<div className="dashboard-layout__body">
								<div className={classNames(['dashboard-layout__container', containerClass])}>{children || <Outlet />}</div>
							</div>

							<Suspense fallback={<div />}>
								<div className="dashboard-layout__footer">
									<Footer />
								</div>
							</Suspense>
						</div>
					</div>
				</div>
			</section>

			<Suspense fallback={<div />}>{!!user?.id && <TermsModal user={user} />}</Suspense>

			<Suspense fallback={<div />}>
				<PlaceModal />
			</Suspense>

			<Suspense fallback={<div />}>
				<DrawerModal />
			</Suspense>
		</>
	);
}

export default React.memo(DashboardLayout);
