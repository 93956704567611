import i18n from 'i18next';
import { initReactI18next, setI18n } from 'react-i18next';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';

import slo from './locales/sl/translation.json';
import sloZod from './locales/sl/zod.json';

export const sl = { translation: slo, zod: sloZod };

export const i18nResources = {
	sl,
};

const init = () => {
	const initOptions: any = {
		compatibilityJSON: 'v3',
		lng: 'sl',
		debug: false, // import.meta.env.VITE_LOCAL
		fallbackLng: 'sl',
		saveMissing: false, // to add new key and value - {t('new.key', 'value for this key')}
		react: {
			useSuspense: false,
		},
	};

	i18n.options.react = initOptions.react;
	setI18n(i18n);

	i18n.use(initReactI18next);
	// if (import.meta.env.VITE_LOCAL === 'true') {
	initOptions.resources = i18nResources;
	// } else {
	// 	const backendInit = {
	// 		loadPath: `${import.meta.env.VITE_BASE_URL}/api/translation/${import.meta.env.VITE_TRANSLATION_VERSION}.json`,
	// 		parse: (data: string, lng: SupportedLanguages) => {
	// 			const parsed = JSON.parse(data);
	// 			const langObj: Record<string, any> = {};
	// 			Object.keys(parsed).forEach((firstLvlKey) => {
	// 				langObj[firstLvlKey] = {};
	// 				Object.keys(parsed[firstLvlKey])
	// 					.filter((secondLvlKey) => (lng === 'sl' ? !secondLvlKey.match(/_[a-z]{2,3}$/) : secondLvlKey.includes(`_${lng}`)))
	// 					.forEach((lngKey) => {
	// 						const secondKey = lng === 'sl' ? lngKey : lngKey.replace(`_${lng}`, '');
	// 						fallback to sl
	// langObj[firstLvlKey][secondKey] = parsed[firstLvlKey][lngKey] ?? parsed[firstLvlKey][secondKey];
	// });
	// });
	//
	// return langObj;
	// },
	// };
	// i18n.use(HttpApi);
	// initOptions.backend = backendInit;
	// }

	z.setErrorMap(zodI18nMap);
	i18n.init(initOptions);
};

init();

export default i18n;
