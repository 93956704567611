import { matchRoutes, useLocation } from 'react-router-dom';
import { RoutesType } from '@core/enums/routes-type.enum';

const routes = [...Object.values(RoutesType).map((value: string) => ({ path: value }))];

export const useCurrentPath = () => {
	const location = useLocation();
	const [{ route }] = matchRoutes(routes, location) || [];

	return route.path as RoutesType;
};
