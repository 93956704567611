import { useLazyQuery } from '@apollo/client';
import { graphql } from '@core/gql/gql';

const GET_PLACES_QUERY = graphql(/* GraphQL */ `
	query GetPlaces {
		entry(type: "places") {
			data {
				... on Entry_Places_Place {
					id
					contracts {
						Payer_name
						StreetPayer
						CityPayer
						PostalCodePayer
						SellTo_name
					}
					FunctionaLoc_code
					external_document_number
					recipient_number
					tax_number
					title
					NAVid
					Street1
					City
					PostalCode
					CRMid
					invoiceNotifications
				}
			}
		}
	}
`);

export const useGetPlaces = () => {
	const [getPlaces, { loading, data, error }] = useLazyQuery(GET_PLACES_QUERY, { fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' });

	return {
		getPlaces,
		data,
		loading,
		error,
	};
};
