import React from 'react';
import { useCurrentPath } from '@hooks/useCurrentPath';
import { RoutesType } from '@core/enums/routes-type.enum';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStore, useUserStore } from '@state/auth.state';
import { useSelectedPlaceStore } from '@state/places.state';

interface ProtectedRouteProps {
	children?: React.ReactNode;
	shouldBeUser?: boolean;
}

const nonAuthRoutes = [
	RoutesType.LOGIN,
	RoutesType.LOGIN_CONFIRM,
	RoutesType.REGISTER,
	RoutesType.FORGOT_PASSWORD,
	RoutesType.CONFIRM_EMAIL,
	RoutesType.SET_PASSWORD,
];

const placeRoutes = [
	RoutesType.INVOICES,
	RoutesType.REPORT_COUNTER_STATUS,
	RoutesType.STATISTICS,
	RoutesType.CONTRACTS,
	RoutesType.EDIT_PLACES,
];

function ProtectedRoute({ children, shouldBeUser = true }: ProtectedRouteProps) {
	const user = useUserStore((store) => store.user);
	const jwt = useAuthStore((store) => store.jwtToken);
	const place = useSelectedPlaceStore((store) => store.place);
	const currentPath = useCurrentPath();

	if (!place?.id && placeRoutes.includes(currentPath)) {
		// console.warn(`user has no place yet, shouldn't be here`);
		return <Navigate replace to={RoutesType.ROOT} />;
	}

	if (!shouldBeUser && user && jwt && nonAuthRoutes.includes(currentPath)) {
		// console.warn('user is authenticated, should be on dashboard');
		return <Navigate replace to={RoutesType.ROOT} />;
	}

	if (shouldBeUser && !user && !jwt) {
		// console.warn('user is not authenticated');
		return <Navigate replace to={RoutesType.LOGIN} />;
	}

	return (children || <Outlet />) as any;
}

export default React.memo(ProtectedRoute);
