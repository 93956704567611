import { useRequest } from '@hooks/useRequest';
import { Endpoints } from '@core/endpoints';
import { useQuery } from '@tanstack/react-query';
import { useSelectedPlaceStore } from '@state/places.state';
import { AxiosError } from 'axios';
import { QueryKeyType } from '@core/enums/query-key.enum';

export const useGetUnread = () => {
	const { request } = useRequest();
	const [place] = useSelectedPlaceStore((store) => [store.place]);

	const { data, isLoading, error, refetch } = useQuery([QueryKeyType.UNREAD_COUNT, place?.id], async () =>
		request({
			url: `${Endpoints.GetUnread}?place_id=${place?.id}`,
			method: 'GET',
		}),
	);

	return {
		unreadCount: data?.count,
		isError: error as AxiosError,
		isLoading,
		refetchUnread: refetch,
	};
};
