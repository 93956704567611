// https://code.specto.si/Butan-plin/117-1-butan-plin-customer-portal-backend/-/blob/develop/app/Enums/CRMTaskType.php
export enum TaskTypes {
	CP_SPREMEMBA_PODATKOV = '1', // to je na urejanju podatkov odjemnega mesta
	CP_REKLAMACIJA_RACUNA = '2', // to je na računih, k maš en link notr pri vsaki postavki
	CP_PODALJSANJE_POGODBE = '3', // to je pri gumbu podaljšaj pogodbo
	CP_SPLOSNO = '4', // to je pa vse ostao
}

// Corresponding drawer types to the tasks from backend
export enum DrawerTypes {
	GENERAL_FORM = 'GENERAL_FORM',
	NEW_CONTRACT = 'NEW_CONTRACT',
	INVOICE_RECLAMATION = 'INVOICE_RECLAMATION',
}
