import { useAuthStore, useUserStore } from '@state/auth.state';
import { shallow } from 'zustand/shallow';
import { JwtToken } from '@core/models/jwt-token.model';
import { Endpoints } from '@core/endpoints';
import { useRequest } from '@hooks/useRequest';
import { useGetPlaces } from '@hooks/api/useGetPlaces';
import { usePlacesStore, useSelectedPlaceStore } from '@state/places.state';
import { Entry_Places_Place } from '@core/gql/graphql';
import { toast } from 'sonner';
import { useTranslation } from 'react-i18next';
import { useCallback, useRef } from 'react';
import { useGetGlobals } from '@hooks/api/useGetGlobals';
import { useApolloStore } from '@state/common.state';
import { getAuthStorageToken } from '@utils/auth-storage-token';

export const useAuth = () => {
	const { t } = useTranslation();
	const { request } = useRequest();
	const { getPlaces } = useGetPlaces();
	const { getGlobals } = useGetGlobals();
	const { clearCache } = useApolloStore();

	const refreshInterval = useRef<NodeJS.Timeout>();
	const [jwtToken, stateLogin, stateLogout] = useAuthStore((store) => [store.jwtToken, store.login, store.logout], shallow);
	const [user, setUser, removeUser] = useUserStore((store) => [store.user, store.setUser, store.removeUser], shallow);
	const setPlacesState = usePlacesStore((store) => store.setPlaces);
	const [selectedPlace, setSelectedPlace] = useSelectedPlaceStore((store) => [store.place, store.setPlace]);

	const login = (token: JwtToken) => {
		clearCache();
		stateLogin(token);
	};

	const logout = useCallback(
		(error?: any) => {
			// Remove auth
			void request({ url: Endpoints.Logout, method: 'POST' });
			stateLogout();
			removeUser();
			clearInterval(refreshInterval.current);

			// Remove places
			setPlacesState([]);
			setSelectedPlace(null);

			// Clear apollo cache
			clearCache();

			if (error) {
				console.error(error);
				toast.error(t('general.errorSession'));
			}
		},
		[clearCache, removeUser, request, setPlacesState, setSelectedPlace, stateLogout, t],
	);

	const setPlaces = useCallback(async () => {
		const res = await getPlaces();

		if (res?.data?.entry?.data) {
			const places = res?.data?.entry?.data as Entry_Places_Place[];
			setPlacesState(places || []);

			// Check if user has a place selected, if not, select the first one
			const updatedSelectedPlace = places.find(({ id }) => id === selectedPlace?.id);
			setSelectedPlace(updatedSelectedPlace?.id ? updatedSelectedPlace : places[0]);
		}
	}, [getPlaces, selectedPlace?.id, setPlacesState, setSelectedPlace]);

	const refresh = useCallback(async () => {
		if (jwtToken && !refreshInterval.current) {
			try {
				const res: JwtToken = await request({ url: Endpoints.Refresh, method: 'POST' });

				if (res?.access_token) {
					stateLogin(res);
				}
			} catch (error) {
				logout(error);
			}
		}
	}, [jwtToken, logout, request, stateLogin]);

	const setRefresh = useCallback(() => {
		const interval = 1000 * 60; // 1 minute

		if (!refreshInterval.current) {
			refreshInterval.current = setInterval(async () => {
				if (!getAuthStorageToken()) {
					clearInterval(refreshInterval.current);
					return;
				}

				await refresh();
			}, interval);
		}
	}, [refresh]);

	const setProfile = useCallback(async () => {
		if (jwtToken && !user) {
			// await refresh();

			try {
				await getGlobals();
				await setPlaces();
				const data = await request({ url: Endpoints.Profile, method: 'GET' });
				setUser(data);
				setRefresh();
			} catch (error) {
				logout(error);
			}
		}
	}, [jwtToken, user, request, setUser, getGlobals, setPlaces, setRefresh, logout]);

	return {
		setProfile,
		login,
		logout,
		refresh,
	};
};
