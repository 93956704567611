import { create } from 'zustand';
import { Global_Admin } from '@core/gql/graphql';
import { DrawerTypes, TaskTypes } from '@core/enums/task-types.enum';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { CachePersistor } from 'apollo3-cache-persist';

interface ApolloStore {
	client: ApolloClient<NormalizedCacheObject> | null;
	setClient: (value: ApolloClient<NormalizedCacheObject>) => void;
	persistor: CachePersistor<NormalizedCacheObject> | null;
	setPersistor: (value: CachePersistor<NormalizedCacheObject>) => void;
	clearCache: () => void;
}

export const useApolloStore = create<ApolloStore>((set, get) => ({
	client: null,
	setClient: (value: ApolloClient<NormalizedCacheObject>) => set({ client: value }),
	persistor: null,
	setPersistor: (value: CachePersistor<NormalizedCacheObject>) => set({ persistor: value }),
	clearCache: () => {
		const { persistor } = get();
		if (!persistor) {
			return;
		}

		console.warn('Clearing cache');
		persistor.purge();
	},
}));

interface MobileStore {
	isMobile: boolean;
	setIsMobile: (value: boolean) => void;
}

export const useIsMobileStore = create<MobileStore>((set) => ({
	isMobile: false,
	setIsMobile: (value: boolean) => set({ isMobile: value }),
}));

interface GlobalsStore extends Global_Admin {
	setGlobals: (value: Global_Admin) => void;
}

export const useGlobalsStore = create<GlobalsStore>((set) => ({
	setGlobals: (value) => set(value),
}));

export interface DrawerModalData {
	type: DrawerTypes | null;
	taskType?: TaskTypes;
	invoice?: string | null;
	key?: string | null;
  successMsg?: string;
}

interface DrawerModalStore {
	data: DrawerModalData;
	setData: (value: DrawerModalData) => void;
}

export const useDrawerModalStore = create<DrawerModalStore>((set) => ({
	data: { type: null, taskType: TaskTypes.CP_SPLOSNO },
	setData: (data) => set({ data }),
}));

interface CookieBannerStore {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
}

export const useCookieBannerStore = create<CookieBannerStore>((set) => ({
	isOpen: false,
	setIsOpen: (value: boolean) => set({ isOpen: value }),
}));
