import React from 'react';
import classNames from 'classnames';
import './Spinner.scss';
import { AnimatePresence, motion } from 'framer-motion';

interface Props {
	center?: boolean;
	type?: 'red' | undefined | null;
}

function Spinner({ center = false, type }: Props) {
	return (
		<AnimatePresence>
			<motion.div
				animate={{ opacity: 1 }}
				className={classNames(['spinner-wrapper', center && 'is-center'])}
				exit={{ opacity: 0 }}
				initial={{ opacity: 0 }}
			>
				<div className={classNames(['spinner', type || ''])} />
			</motion.div>
		</AnimatePresence>
	);
}
export default React.memo(Spinner);
