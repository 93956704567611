import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import axios from 'axios';
import { useCallback } from 'react';
import { getAuthStorageToken } from '@utils/auth-storage-token';

const client = axios.create({ baseURL: `${import.meta.env.VITE_BASE_URL}/api` });

export const useRequest = () => {
	const request = useCallback(
		async ({ returnAllData, ...options }: AxiosRequestConfig & { returnAllData?: boolean }, forceToken?: string) => {
			const token = forceToken || getAuthStorageToken();

			if (token) {
				client.defaults.headers.common.Authorization = `Bearer ${token}`;

				if (options.headers) {
					options.headers.authorization = `Bearer ${token}`;
				} else {
					options.headers = {
						authorization: `Bearer ${token}`,
					};
				}
			} else {
				delete client.defaults.headers.common.Authorization;
				if (options.headers) {
					delete options.headers.authorization;
				}
			}

			const onSuccess = (response: AxiosResponse) => (returnAllData ? response : response?.data?.records);

			return client(options).then(onSuccess);
		},
		[],
	);

	return { request };
};
