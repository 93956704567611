import React, { Suspense } from 'react';
import { useApolloClient } from '@hooks/useApolloClient';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ApolloProvider } from '@apollo/client';
import { Toaster } from 'sonner';
import * as Sentry from '@sentry/react';

import { Navigation } from '@/Navigation';

const CookieBanner = React.lazy(() => import('@modules/CookieBanner/CookieBanner'));
const AppVersion = React.lazy(() => import('@modules/AppVersion/AppVersion'));

const queryClient = new QueryClient();

const dsn = import.meta.env.VITE_SENTRY_DSN;

if (dsn) {
	Sentry.init({
		dsn,
		integrations: [new Sentry.BrowserTracing()],
		sampleRate: 0.25,
		tracesSampleRate: 0.25,

		// Common errors that we can ignore
		ignoreErrors: [
			'Request failed with status code 401',
			'signal is aborted without reason',
			'Unable to preload CSS /assets/*',
			'error loading dynamically imported module: *',
			'Large Render Blocking Asset',
			'Importing a module script failed.',
		],
	});
}

function App() {
	const { client } = useApolloClient();

	if (!client) {
		return null;
	}

	return (
		<ApolloProvider client={client}>
			<QueryClientProvider client={queryClient}>
				<GoogleOAuthProvider clientId={import.meta.env.VITE_GOOGLE_SECRET}>
					<GoogleReCaptchaProvider reCaptchaKey={import.meta.env.VITE_CAPTCHA_SITE_KEY}>
						<Navigation />

						<Toaster />
						{/* <ReloadPrompt /> */}

						<Suspense fallback={<div />}>
							<CookieBanner />
						</Suspense>

						<Suspense fallback={<div />}>
							<AppVersion />
						</Suspense>
					</GoogleReCaptchaProvider>
				</GoogleOAuthProvider>
			</QueryClientProvider>
		</ApolloProvider>
	);
}

export default React.memo(App);
