export enum RoutesType {
	ROOT = '/',

	// Auth
	LOGIN = '/login',
	LOGIN_CONFIRM = '/login/:hash',
	REGISTER = '/register',
	CONFIRM_EMAIL = '/confirm-email/:hash',
	FORGOT_PASSWORD = '/forgot-password',
	SET_PASSWORD = '/set-password/:hash',

	// Error
	ERROR = '/error',

	// Dashboard
	INVOICES = '/invoices',
	ORDERS = '/orders',
	REPORT_COUNTER_STATUS = '/report-counter-status',
	STATISTICS = '/statistics',
	FORMS = '/forms',
	CONTRACTS = '/contracts',
	SETTINGS = '/settings',
	EDIT_PLACES = '/edit-places',
	MESSAGES = '/messages',
	MESSAGE_DETAILS = '/messages/:id',

	// Other
	// PRIVACY_POLICY = '/privacy-policy',
	// COOKIE_NOTICE = '/cookie-notice',
	// CONTACTS = '/kontakti',
	// TERMS_AND_CONDITIONS = '/terms-and-conditions',
	FAQ = '/faq',
	ERROR_PAGE = '/error',
}
