import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { CachePersistor, LocalStorageWrapper } from 'apollo3-cache-persist';
import { useEffect } from 'react';

import { createPersistLink } from '@utils/apollo/persistLink.helper';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { useAuthStore, useUserStore } from '@state/auth.state';
import { getAuthStorageToken } from '@utils/auth-storage-token';
import { useApolloStore } from '@state/common.state';
import { onApiError } from '@utils/onApiError';

const authLink = setContext((_, { headers }) => {
	const token = getAuthStorageToken();

	return { headers: { ...headers, authorization: token ? `Bearer ${token}` : '' } };
});

export const useApolloClient = () => {
	const logout = useAuthStore((store) => store.logout);
	const removeUser = useUserStore((store) => store.removeUser);
	const { client, setClient, setPersistor } = useApolloStore();

	useEffect(() => {
		async function init() {
			const cache = new InMemoryCache();
			const newPersistor = new CachePersistor({
				cache,
				storage: new LocalStorageWrapper(window.localStorage),
				debug: import.meta.env.VITE_LOCAL === 'true',
				trigger: 'write',
			});
			await newPersistor.restore();
			setPersistor(newPersistor);
			const persistLink = createPersistLink();
			const httpLink = createHttpLink({ uri: import.meta.env.VITE_GRAPHQL_URL });
			const apolloClient = new ApolloClient({
				link: ApolloLink.from([
					onError(({ graphQLErrors }) => {
						if (graphQLErrors?.some((error) => error.message === 'Nepooblaščen uporabnik.')) {
							logout();
							removeUser();
						}

						graphQLErrors?.forEach((err) => onApiError(err.message));
					}),
					persistLink.concat(authLink.concat(httpLink)),
				]),
				defaultOptions: {
					watchQuery: {
						fetchPolicy: 'cache-and-network',
						nextFetchPolicy: 'cache-first',
					},
				},
				cache,
				connectToDevTools: import.meta.env.VITE_PRODUCTION === 'false',
			});
			setClient(apolloClient);
		}

		if (!client) {
			init();
		}
	}, [client, logout, removeUser, setClient, setPersistor]);

	return {
		client,
	};
};
